.button {
	cursor: pointer;
}

.button-push-bump {
    transform: scale(0.9);
    transition: all 250ms;
    box-shadow: 0px 2px 16px rgba(246, 167, 95, 0.4);
}

.button-push-bump:hover {
    box-shadow: 0px 4px 8px rgba(246, 167, 95, 0.3);
    transform: scale(1);
}

.button-push-bump:active {
    box-shadow: 0px 0px 16px 1px rgba(246, 167, 95, 0.7) !important;
    transform: scale(0.85) !important;
}

button:active,
button:focus {
    outline: none;
}