:root {
  --color-orange-primary: #f6a560;
  --color-orange-light: #f7c56e;
  --color-orange-lightest: #fde6d6;

  --color-purple-primary: rgba(246, 113, 115, 0.9);
  --color-purple-light: rgba(246, 137, 106, 0.9);

  --color-peach-light: #fff9f4;

  --gradient-orange-purple: linear-gradient(180deg, #f6a75f 0%, #f65f7a 100%);

  --box-shadow-orange-light: 0px 4px 32px rgba(246, 167, 95, 0.4);

  --color-bg-primary: #121212;
  --color-fg-primary: #1d1d1d;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: rgb(246, 167, 95);
  background: -moz-linear-gradient(
    180deg,
    rgba(246, 167, 95, 1) 0%,
    rgba(246, 95, 122, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(246, 167, 95, 1) 0%,
    rgba(246, 95, 122, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(246, 167, 95, 1) 0%,
    rgba(246, 95, 122, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6a75f", endColorstr="#f65f7a", GradientType=1);

  background: var(--color-bg-primary);
  color: #fff;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  overflow: hidden;
}

li {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}
body * {
  transition: all 250ms;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

hr {
  opacity: 0.2;
}
