.center-both {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

.uncopyable {
	-webkit-touch-callout: none !important;
	-webkit-user-select: none !important;
	-khtml-user-select: none !important;
	-moz-user-select: none !important;
	-ms-user-select: none !important;
	user-select: none !important;
}

.w-100 {
	width: 100% !important;
}

.h-100 {
	height: 100% !important;
}

.theme-text {
	font-size: 15px;
	font-weight: 500;
	color: #F7806F;
}

.text-center {
	text-align: center;
}

.flex-grow-1 {
	flex-grow: 1;
}

.p-1 {
	padding: 1rem;
}

.p-2 {
	padding: 2rem;
}

.p-3 {
	padding: 3rem;
}

.p-4 {
	padding: 4rem;
}