.dashboard {
    height: 101vh;
    width: 100vw;
    display: flex;
}

.main-area {
    height: 100vh;
    width: 1%;
    flex-grow: 1;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background: rgba(247, 242, 251, 1);
    background: var(--color-bg-primary);
}

.content-area {
    padding: 2rem 1rem;
    height: 1%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}