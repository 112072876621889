.scroll {
	overflow: hidden;
	overflow-y: scroll;
	height: 100%;
	scrollbar-color: #fee2e0 #f65f7a;
	scrollbar-width: auto;
	scroll-margin: 3rem;
}

.scroll::-webkit-scrollbar {
	width: 1.25rem;
	height: 1rem;
}

.scroll::-webkit-scrollbar-track {
	/* background: #feedeb; */
	background: #fee2e0;
	border-radius: 9999px;
	padding-right: 1.25rem;
	transition: all 350ms;
}

.scroll::-webkit-scrollbar-track:hover {
	/* background: #fee2e0; */
}

.scroll::-webkit-scrollbar-thumb {
	box-shadow: 4px 0px 32px rgba(246, 167, 95, 0.25);
	border-radius: 9999px;
	background: linear-gradient(180deg, #f6a75f 0%, #f65f7a 100%);
	transition: all 150ms;
	opacity: 1;
}

.scroll::-webkit-scrollbar-thumb:hover {
	opacity: 1;
}

.scroll-small::-webkit-scrollbar {
	width: 1rem;
}

.scroll-light::-webkit-scrollbar-thumb {
	background: linear-gradient(180deg, #f67f6d 0%, #f6a260 100%);
}
