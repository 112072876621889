.vendor-list-row {
    .not-available {
        font-size: 14px !important;
        font-weight: 600 !important;
        color:rgba(255, 255, 255, 0.7)!important;
    }

    .name {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        font-size: 22px;
        font-weight: 200;
        text-transform: capitalize;

        & > div::first-letter {
            font-weight: 400;
        }
    }

    .status {
        font-size: 11px;
        font-weight: 500;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #90ef90;
        margin-top: 0.25rem;
        text-transform: uppercase;
        height: 100%;

        &:not(.verified) {
            color: #fc6c85;
        }

        &:not(svg) {
            font-size: 12px;
        }

        .text {
            margin-left: 0.25rem;
            margin-bottom: -1px;
        }
    }

    .phone {
        font-size: 16px;
        font-weight: 400;
    }
    .address {
        font-size: 16px;
        font-weight: 300;

        p {
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    &.loading {
        .address {
            padding-top: 2rem;
        }

        .phone {
            padding-top: 1rem;
        }

        .profile-pic {
            background: transparent;
        }

        td, .avatar  {
            width: 100%;
            opacity: 0.5;
            content: "";
            margin: 0 1rem;
            border-radius: 3px;
            background: linear-gradient(354deg, #dce4e2, #b7b9b9, #d5dddd, #afb0b0);
            background-size: 800% 800%;

            -webkit-animation: AnimationName 1s ease infinite;
            -moz-animation: AnimationName 1s ease infinite;
            animation: AnimationName 1s ease infinite;

            * {
                opacity: 0;
            }
        }

        .profile-pic {
        }

        @-webkit-keyframes AnimationName {
            0% {
                background-position: 37% 0%;
            }
            50% {
                background-position: 64% 100%;
            }
            100% {
                background-position: 37% 0%;
            }
        }
        @-moz-keyframes AnimationName {
            0% {
                background-position: 37% 0%;
            }
            50% {
                background-position: 64% 100%;
            }
            100% {
                background-position: 37% 0%;
            }
        }
        @keyframes AnimationName {
            0% {
                background-position: 37% 0%;
            }
            50% {
                background-position: 64% 100%;
            }
            100% {
                background-position: 37% 0%;
            }
        }
    }
}
