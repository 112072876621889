/*--------------| ANIMATIONS |---------------*/
.rise-up-fade-in, .fall-down-fade-in, .right-fade-in, .left-fade-in {
	animation-name: appear-up;
	animation-duration: 1s;
	animation-fill-mode: none;
	animation-timing-function: ease;
	opacity: 0;
}

.appear-fast {
	animation-duration: 750ms;
}

.fall-down-fade-in {
	animation-name: appear-down;
}

.right-fade-in {
	animation-name: appear-right;
}

.left-fade-in {
	animation-name: appear-left;
}

@keyframes appear-up {
	0% {
		opacity: 0;
		transform: translateY(30px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes appear-down {
	0% {
		opacity: 0;
		transform: translateY(-30px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes appear-right {
	0% {
		opacity: 0;
		transform: translateX(30px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes appear-left {
	0% {
		opacity: 0;
		transform: translateX(-30px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

.fill-mode-forwards {
	animation-fill-mode: forwards !important;
}