.table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.custom-table {
  position: relative;
  flex-direction: column;
  display: flex;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  cursor: pointer;

  tbody,
  thead {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    transition: all 0.3s ease;
  }

  thead {
    position: sticky;
    top: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 999;
    background: var(--color-fg-primary);

    td {
      font-size: 13px;
      font-weight: 500;
      color: rgba(255,255,255, 0.8);
      text-transform: capitalize;
    }
  }

  tbody {
    flex: 1;

    tr:hover {
      box-shadow: 0 1px 32px rgba(0, 0, 0, 0.15);
    }

    &:hover {
      tr {
        border-color: rgba(0, 0, 0, 0.075);
      }
    }
  }

  tr {
    width: -webkit-fill-available;
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease;
    border-bottom: 1px solid;
    border-color: rgba(0, 0, 0, 0.03);
    padding-right: 2rem !important;
  }

  td {
    flex: 1;
    padding: 0 1rem;
    display: flex;
    width: 50px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 15px;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.7);
  }

  &.gridEnabled {
    thead {
      height: 0;
      opacity: 0;
    }

    tbody {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}
