.card-container {
	display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-flow: dense;
    padding-left: 1rem;
    padding-bottom: 8rem;
    height: 100% !important;
    max-width: 100%;
    margin: 0 auto;
    flex-grow: 1;

    border-radius: 15px;
    box-shadow: inset 0px 0px 28px rgba(246, 165, 96, 0.01), 0px 0px 28px rgba(246, 165, 96, 0.02);
    
    transition: all 250ms;
}

.card {
    height: 350px;
    padding: 1rem;
    /* margin-right: 1rem; */
    margin: 1rem;
    overflow: hidden;
    background: var(--color-fg-primary);
	border-radius: 15px;
    /* box-shadow: 0px 4px 28px rgba(246, 165, 96, 0.35); */
    transition: all 250ms;
}

.card-hover:hover {
    box-shadow: 0px 4px 28px rgba(246, 165, 96, 0.55);
}

.card-tall {
    grid-row-end: span 2;
    height:  732px;
}

.card-wide {
    grid-column-end: span 2;
}

/* .card-widest {
    grid-column-end: span 3;
} */

@media only screen and (max-width: 1024px) {
    .card-wide {
        grid-column-end: span 1;
    }
  }