.pagination-toolbar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.pagination-toolbar {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 600px;
  cursor: pointer !important;
  font-size: 16px;
  font-weight: 400;
  color:rgba(255, 255, 255, 0.7);

  div {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button {
    webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    vertical-align: middle;
    color: inherit;
    font: inherit;
    border: 0;
    background: transparent;
    padding: 0;
    margin: 0;
    outline: 0;
    border-radius: 0;
    text-align: inherit;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 900;
    color: var(--color-purple-primary);
    text-transform: uppercase;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
    display: inline-block;
    width: 32px;
    height: 32px;
    border: 2px solid var(--color-orange-primary);
    border-radius: 5px;
    text-align: center;
  }

  input[type="number"],
  span {
    margin: 0 0.5rem;
    font-size: 17px;
    font-weight: 900;
    color: var(--color-purple-primary);
  }
}
