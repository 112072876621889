.search-container {
    padding: 0.5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.search-container input:focus, .search-container input:active {
    border: 0 !important;
    outline: transparent;
}

.search-table {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 1rem;
    border: 1px solid transparent;
    padding: 0.25rem 0.5rem;
    padding-right: 1rem;
}

.search-table .material-icons{
    font-size: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #B0B0B0;
}

.search-table .material-icons:hover{
    color: #f6836c;
}


.search-table.active {
    flex: 1;
    border-color: rgba(0,0,0,0.1);
    border-color: #F6A161;
}

.search-table input {
    flex: 1;
    border: 0 !important;
    color: transparent;
    width: 0px;
    font-size: 16px;
}

.search-table.active input {
    border: 0 !important;
    color: rgba(0,0,0,0.5);
}

.clear-input {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.75rem;
    color: #F66379;
}