.login-input, .login-button, .login-input:-internal-autofill-selected ,input:-internal-autofill-selected, input:-webkit-autofill  {
		width: 100%;
	height: 2.5rem;
	margin-top: 2rem;
	padding: 0 2rem;

	background: rgba(246, 158, 98, 0.2) !important;
	border: none;
	outline: none;
	border-radius: 50px;

	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 21px;
	color: rgba(246, 165, 96, 0.9) !important;
}

.login-input::placeholder {
	color: rgba(246, 165, 96, 0.5);
}

.login-input:focus {
	/* border: 1px solid #f67472; */
}


.login-button {
	font-weight: 600;
	width: 100%;
    /* margin-top: 0 !important; */
    background: #F69E62 !important;
    color: #fff !important;
    text-align: center;
    vertical-align: middle;
    box-shadow: 0px 4px 28px rgba(246, 136, 106, 0.5) !important;;
}

/*-------------------------| EXPERIMENTAL INPUT |--------------------*/
.input {
	width: 100%;
	position: relative;
	font-size: 1.5em;
	background: linear-gradient(21deg, #10abff, #1beabd);
	background: linear-gradient(180deg, rgba(246, 167, 95, 1) 0%, rgba(246, 95, 122, 1) 100%);
	padding: 3px;
	display: inline-block;
	border-radius: 9999em;
}

.input *:not(span) {
	position: relative;
	display: inherit;
	border-radius: inherit;
	margin: 0;
	border: none;
	outline: none;
	padding: 0 .325em;
	z-index: 1;
}

.input *:not(span):focus + span {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}

.input span {
	-webkit-transform: scale(0.993, 0.94);
	transform: scale(0.993, 0.94);
	-webkit-transition: opacity .25s, -webkit-transform .5s;
	transition: opacity .25s, -webkit-transform .5s;
	transition: transform .5s, opacity .25s;
	transition: transform .5s, opacity .25s, -webkit-transform .5s;
	opacity: 0;
	position: absolute;
	z-index: 0;
	margin: 4px;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	border-radius: inherit;
	pointer-events: none;
	box-shadow: inset 0 0 0 3px #fff, 0 0 0 4px #fff, 3px -3px 30px #1beabd, -3px 3px 30px #10abff;
}

input {
	font-family: inherit;
	line-height: inherit;
	color: #2e3750;
	width: 100%;
	height: 100%;
	/* min-width: 12em; */
}

::-webkit-input-placeholder {
	color: #cbd0d5;
}

::-moz-placeholder {
	color: #cbd0d5;
}

:-ms-input-placeholder {
	color: #cbd0d5;
}

::-ms-input-placeholder {
	color: #cbd0d5;
}

::placeholder {
	color: #cbd0d5;
}
