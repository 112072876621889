.hover-rise , .hover-rise-small {
    transform: scale(0.99) !important;
}

.hover-rise-small {
    transform: scale(0.99) !important;
}

.hover-rise:hover, .hover-rise-small:hover {
    box-shadow: 0px 4px 28px rgba(0,0,0,0.25) !important;
    transform: scale(1) !important;
}

