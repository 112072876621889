.reason-add{
.vehicle-form input:not(.add-vehicle-button),
.vehicle-form{
	margin-top: 20px;
}
.vehicle-form label,
.vehicle-form textarea {
	margin: 0.5rem;
	display: inline-block;
	width: 44%;
	border-radius: 5px;
	border-color: transparent;
	border-radius: 999px;
}

.vehicle-form input:not(.add-vehicle-button), .vehicle-form textarea {
	padding: 0.25rem 1rem;

	text-align: center;
	background: #fccfc9;
	background: #dedcde;
	background: #fcd5cf;
	font-size: 14px;
	font-weight: 700;
	color: #f54f36;
}

.vehicle-form input:not(.add-vehicle-button):focus, .vehicle-form textarea {
	/* border-color: #F68A7B; */
	background: #FFF;
	outline-color: #f68a7b;
	-moz-outline-radius: 999px;
}

.vehicle-form input, .vehicle-form textarea {
	padding: 0.5rem;
	margin-bottom: 1rem;
	/* width: 35%; */
}

.add-hehicle-button {
	margin: 0.5rem auto !important;
}

.vehicle-button-area {
	display: flex;
	padding: 0.5rem;
	margin: 0.5rem;
}

.all-vehicle-button,
.add-more-button {
	padding: 0.5rem 1.5rem;
    border: 1px solid red;
    border-radius: 999px;
    margin: 1rem;
    font-weight: 700;
}

.all-vehicle-button, .add-vehicle-button {
	color: #fff;
    background: #F69A63;
    border-color: #F69A63;
}

.all-vehicle-button:hover, .add-vehicle-button:hover {
	color: #F69A63;
    background: #fff;
    border-color: #F69A63;
}

.add-more-button {
	color: #fff;
    background: #F6816D;
    border-color: #F6816D;
}

.add-more-button:hover {
	color: #F6816D;
    background: #fff;
}

.success-text, .fail-text {
	color: green;
	font-size: 17px;
    font-weight: 400;
    text-shadow: 0px 2px rgba(0,0,0,0.1);
}

.fail-text {
	color: red;
}
}
