.stat-card {
    background: #F7C56E;
    box-shadow: 0px 4px 32px rgba(246, 167, 95, 0.4);
    border-radius: 15px;
    margin: 2rem;
    margin-top: 0;
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.stat-card li{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
}

.stat-card li:nth-child(2){
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.5);
}

.stat-card .counter {
    position: relative;
    width: 5rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 999px;
    background: #F6DC8D;
    border: 2px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
}

.stat-card .counter span {
    font-weight: 600;
    font-size: 36px;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    opacity: 1;
}

.stat-card .title {
    margin-left: 1rem;
    font-size: 22px;
    color: rgba(255, 255, 255, 0.9);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}


.stat-card .loader {
    
    width: 5rem;
    height: 5rem;
    border-radius: 999px;
    /* border: 2px solid rgba(255, 255, 255, 0.5); */

    background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    position: absolute;
    top: -2px;
    left: -2px;
    -webkit-animation: load3 0.5s infinite linear;
    animation: load3 0.5s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 0.9;
  }
  .stat-card .loader:before {
    width: 50%;
    height: 50%;
    background: #ffffff;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  .stat-card .loader:after {
    background: #F6DC8D;
    width: 90%;
    height: 90%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 5%;
    left: 5%;
  }
  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  



  /* Loader 6 */
.loader-6 {
    display: flex;
    justify-content: center;
    align-items: center;
	margin: auto;
	height: 5rem;
    width: 5rem;
    position: relative;
}

.loader-6:before,
.loader-6:after {
	content: "";
	display: block;
	position: absolute;
    top: -7%; left: -1rem;
    right: 0; bottom: 0;
	margin: auto;
	height: 6rem;
	width: 6rem;
	border: 4px solid #FFF;
	border-radius: 50%;
	opacity: 0;
	-webkit-animation: loader-6-1 1s cubic-bezier(0.075, 0.820, 0.165, 1.000);
	        animation: loader-6-1 1s cubic-bezier(0.075, 0.820, 0.165, 1.000);
}
@-webkit-keyframes loader-6-1 {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(0); opacity: 1; }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1.05); opacity: 0; }
}
@keyframes loader-6-1 {
	0%   { transform: translate3d(0, 0, 0) scale(0); opacity: 1; }
	100% { transform: translate3d(0, 0, 0) scale(1.1); opacity: 0; }
}
.loader-6:after {
	-webkit-animation: loader-6-2 1s cubic-bezier(0.075, 0.820, 0.165, 1.000) .25s;
	        animation: loader-6-2 1s cubic-bezier(0.075, 0.820, 0.165, 1.000) .25s;
}
@-webkit-keyframes loader-6-2 {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(0); opacity: 1; }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); opacity: 0; }
}
@keyframes loader-6-2 {
	0%   { transform: translate3d(0, 0, 0) scale(0); opacity: 1; }
	100% { transform: translate3d(0, 0, 0) scale(1); opacity: 0; }
}